
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;	
	font-family: Raleway, sans-serif;
}

.forgot-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
      background-image: url(./assets/footer2.jpg);
      background-size:cover;
      background-position:right;
      background-attachment: fixed;
 
}

.screen {		
  background-image: radial-gradient( circle farthest-corner at 17.1% 22.8%,  rgb(248, 98, 98) 30%, rgba(160,6,6,1) 90% );
	position: relative;	
	height: 500px;
	width: 360px;	
	box-shadow: 0px 0px 24px #1b191a;
  border-radius: 20px;
}

.screen__content {
	z-index: 1;
	position: relative;	
	height: 100%;
}

.screen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.screen__background__shape {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
	height: 520px;
	width: 520px;
	background: #FFF;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
	height: 220px;
	width: 220px;
	background: #ac3737;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape3 {
	height: 540px;
	width: 190px;
  background-image: radial-gradient( circle farthest-corner at 17.1% 22.8%,  rgb(248, 98, 98) 30%, rgba(160,6,6,1) 90% );
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape4 {
	height: 400px;
	width: 200px;
	background: #eb5f66;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}

.login {
	width: 320px;
	padding: 30px;
	padding-top: 156px;
}

.login__field {
	padding: 20px 0px;	
	position: relative;	
}

.login__icon {
	position: absolute;
	top: 30px;
  left: 5px;
	color: #e64e4e;
}

.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 25px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}
.login__input::placeholder{
  color: black;
}

.forgot__password{
  font-weight: 700;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #9e6767;
}

.login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: #161313;
	box-shadow: 0px 2px 2px #ee6464;
	cursor: pointer;
	transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6A679E;
	outline: none;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #7875B5;
}

.social-login {	
	position: absolute;
	height: 140px;
	text-align: center;
	top: 80px;
	left: 20px;
	color: #0a0a0a;
}

