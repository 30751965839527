*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid red; */
}
.searchHeader{
  padding:20px 30px 0px 30px;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap;
}
.searchHeader input{
  width: 300px;
  padding: 10px;
  border: 1px solid;
  border-radius: 50px;
  text-align: center;
}
.member-list{
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 40px;
}
.member-box{
  border: none;
  padding: 10px;
  margin: 10px 15px;
  box-shadow: 2px 2px 5px rgb(110, 109, 109);
  /* background-color: rgb(233, 224, 227); */
  border-radius: 20px;
}
.member-box p,.member-box1 p{
  font-size: 12px;
  line-height: normal;
}
.member-box1 td{
  font-size: 12px;
  border: 1px solid rgb(189, 185, 185);
}
.small-photo{
    justify-content: center;
    text-align: center;
    display: flex;
    width: 100%;
    height: 130px;
    /* border: 1px solid; */
    border-radius: 10px 10px;
}
.operation-buttons{
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* for button 1 and 2 */

.button1{
  background-color: green;
  color: white;
  padding: 8px;
  margin: 10px 20px;
  width: 100px;
  font-weight: bold;
  border: 1px solid;
  border-radius: 10px;
}

.button2{
  background-color: red;
  color: white;
  padding: 8px;
  margin: 10px 20px;
  width: 100px;
  font-weight: bold;
  border: 1px solid;
  border-radius: 10px;
}

.large-photo-modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}
.large-photo{
  height: 350px;
  width: 100%;
}

.modal-content {
  margin: 3% auto;
  /* border: 1px solid #888; */
  width: 100%;
  background-color: #fff;
  position: relative;
}

.modal-contentPhotoApp {
  margin: 3% auto;
  padding: 20px;
  border: 1px solid #888;
  /* width: 50%; */
  background-color: #fff;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.profile-details{
  display: flex;
  flex-direction: row;
}
.profile-details p{
  font-size: 14px;
}
.profile-details .para{
  font-weight: bold;
}
.close {
  color: #8b8a8a;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


p{
  line-height: 10px;
  font-size: 14px;
}


.viewButtons1, .viewButtons2{
  border:1px solid #462f46;
  width: 90px;
  color: white;
  border-radius: 10px;
  margin: 0px 5px;
  font-size: 12px;
  padding: 5px;
  text-decoration: none;
  background-color: #462f46;
}
.viewButtons1:hover{
  background-color: #b3b3d6;
  color: #000;
  border: 1px solid #b3b3d6;
}
.viewButtons2:hover{
  background-color: #fc0c0c;
  color: white;
  border: 1px solid #f30d0d;
}

.profile-buttons{
  text-align: center;
}

.filter-buttons{
  background-color: #f1eded;
  padding: 15px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  margin: 0px 0px 10px 0px;
  box-shadow: 3px 3px 8px rgb(163, 160, 160);
}

.filter-buttons input{
  width: 300px;
  border: 1px solid;
  border-radius: 50px;
  padding: 10px;
  text-align: center;
}

.filterButtonsBack{
  padding: 5px 5px 5px 10px;
  display: flex;
  font-weight: 550;
  justify-content: end;
  align-content: center;
}

.filterButtons{
  padding: 5px;
  text-decoration: none;
  color: black;
  font-weight: 550;
  /* margin: 0px 20px; */
  border-radius: 10px;
  text-align: center;
  display: flex;
}
.photoApprove{
  box-shadow:2px 2px 5px #888;
}

.photoApprove th{
  border: 1px solid rgb(189, 185, 185);
  background-color: rgb(102, 5, 5);
  color: white;
}
.photoApprove tr{
  background-color: rgb(241, 237, 237);
  color: black;
}
.photoApprove tr:hover{
  background-color: rgb(253, 253, 253);
}

.editProfileTitle{
  background-color: #f1eded;
  text-align: start;
  margin: 0px 0px 10px 0px;
  box-shadow: 3px 3px 8px rgb(163, 160, 160);
}



/* ===================================================================================== */

/* ====================DATA TABLE CSS===================== */

.custom-datatable .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-datatable .dataTables_info {
  display: none;
}

.custom-datatable thead:last-child {
  display: none;
}

.custom-datatable .dataTables_length label {
  font-size: 12px;
}

.custom-datatable .dataTables_length select {
  width: 50px;
}

 .custom-datatable thead th {
  background: #b3b3d6;
  background: -webkit-linear-gradient(to right, #b3b3d6, #b3b3d6); 
  background: linear-gradient(to right, #b3b3d6, #b3b3d6);
 color: #000;
}


.custom-datatable .dataTables_paginate .pagination {
  color: #007bff; /* Example text color */
  position: relative; /* Ensure proper positioning */
  z-index: 0; /* (prevent from overlapping) */
}

.custom-datatable .dataTables_paginate .page-item.active .page-link {
  background-color: #007bff; /* Active page background */
  color: white; /* Active page text color */
  
}

.custom-datatable .dataTables_paginate .page-link {
  color: #007bff; /* Default page link color */
}


/* //=========pagination of the table hide when modal is open==========// */
/* Add this to your Members.css or any relevant CSS file */
.hide-pagination .dataTables_paginate {
  display: none !important;
}


.hover-enlarge {
  transition: transform 0.3s ease-in-out;
}

.hover-enlarge:hover {
  transform: scale(1.5);
  z-index: 10;
}
