*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* border: 1px solid greenyellow; */
    font-variant-numeric: lining-nums;
}
.EntryBtn:hover{
    transform:rotate(20deg);
}

.scanBtn{
    background: #129be4;
    background: -webkit-linear-gradient(to right, #129be4, #73caca); 
    background: linear-gradient(to right, #129be4, #73caca);
    color: white;
    border: none;
}