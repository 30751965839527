*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* border: 1px solid greenyellow; */
  }
  
    @media (min-width: 320px) {
      .main-step {
        /* width: 200px; Adjust this value for tablet screens */
        flex-wrap: wrap;
      }
    }
    .container-first,.container-second,.container-third,.container-fourth,.regimg{
      width: 100%;
      background-image: url(../../../assets/footer2.jpg);
      background-size:cover;
      background-position:right;
      background-attachment: fixed;
    }
    .selfimg{
      width: 100%;
      height: 300vh;
    }
    