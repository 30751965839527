*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* border: 1px solid greenyellow; */
  }
  
    @media (min-width: 320px) {
      .main-step {
        /* width: 200px; Adjust this value for tablet screens */
        flex-wrap: wrap;
      }
    }
    .container-first,.container-second,.container-third,.container-fourth,.regimg{
      width: 100%;
      background-image: url(../assets/background_box_design.png);
      background-size:cover;
      background-position:right;
      background-attachment: fixed;
    }
    .main-step {
      display: flex;
  flex-direction: column;
  align-items: flex-start;
    }
 
    .selfimg{
      width: 100%;
      height: 300vh;
    }
    .both{
      margin: auto auto;
    }
    .acceptable,.unacceptable{
      width: 100%;
      height: auto;
      align-items: center;
      transition: all 1s;
    }
    .acceptable:hover{
      transform: scale(2.2);
      align-content: center;
    }
    .unacceptable:hover{
      transform: scale(2.6);
      align-content: center;
    }
    
    .nextbtn,.backbtn {
     
      font-size: 19px !important;
      font: bolder !important;
      background-color: rgb(134, 28, 18) !important;
      font-weight: 25px !important;
      color: white !important;
    }
    
    .place::placeholder {
      color: rgb(158, 149, 149);
      opacity: 1; /* Firefox */
    }