*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* border: 1px solid greenyellow; */
    font-variant-numeric: lining-nums;
  }
  .header{
    display: flex;
    justify-content:space-around ;
    align-items: center;
    background-color: rgb(233, 224, 227);
    color:rgb(134, 28, 18);
    font-weight: 25px;
  }
  .wrap{
    background-image: url(./assets/background_box_design.png);
    background-size: cover;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .footerimg{
    background-image: url(./assets/background_desgin.jpg) !important;
    background-size: cover;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .login-container{
    /* height: 100vh; */
    /* justify-content: center; */
    display: flex;
    /* align-items:center;     */
  }
  .login-form{
    margin: auto;
    width: 25em;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 5px 60px;
    align-items: center;
    justify-content: space-around;
    color: rgb(236, 11, 11);  
  }
  
  h1 {
    text-align: center;
    padding-bottom: 30px;
  }
  
  #input,#input1{
    width: 100%;
    padding: 13px;
    font-size: 16px;
    margin-bottom: 20px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgb(29, 28, 28);
    color: rgb(24, 23, 22);
  }
  #input::placeholder{
    color: rgb(17, 17, 17);
    background-color: transparent;
  }
  #input1::placeholder{
    background-color: transparent;
    color: rgb(15, 15, 15);
  }
  
  .login-button,.register-button{
    display: flex;
    justify-content: center;
    
  }
  .login-btn:hover{
    color: black;
    background-color: rgb(255, 94, 7);
    border: 1px solid rgb(252, 90, 3);
  }
  .forgot:hover{
    color: rgb(22, 44, 2);
  }
  .login-btn,.register-btn{
    padding: 10px 15px;
    font-size: 16px;
    color:rgb(26, 25, 24);
    background-color: transparent;
    border:1px solid rgb(19, 18, 18);
    margin-top: 17px;
    cursor: pointer;
    border-radius: 5px;
  }
  .register-btn{
    border: none;
    border-bottom: 1px solid rgb(32, 32, 32);
    margin-top: 4px !important;
    font-size: 13px;
  }
  .footer1-container{
    width: 100%;
    background-size: cover;
  }
  p{
    line-height: 25px;
    font-size: large;
  }
  /* ----------popup--------- */

 .popup span{
    /* position: absolute; */
    /* top: 50%;
    left: 50%; */
    transform: translate(-50%, -50%);
}

.popup b{
    font-size: 1em;
    font-family: serif;
    color: red;
    text-align: center;
    animation: animate 
        6.1s linear infinite;
}

.timetable{
  background-color: rgb(233, 224, 227);
}

.gif-button {
  background-color: transparent; /* Make the button background transparent */
  border: none; /* Remove the button border */
  color: red; /* Set the text color to red or any color you like */
  font-size: 24px; /* Adjust the font size as needed */
  text-transform: uppercase; /* Convert text to uppercase */
  font-weight: bold; /* Make the text bold */
  animation: colorChange 1s infinite; /* Apply an animation to change the text color */
  border: none;
  border-bottom: 1px solid white;
  margin-top: 13px;
  font-size: 18px;
}

@keyframes colorChange {
  0% {
    color: rgb(247, 244, 244);
  }
  25% {
    color: rgb(247, 89, 5);
  }
  50% {
    color: #fffefe
  }
  100% {
    color: rgb(247, 89, 5)
  }
 
}
