*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* border: 1px solid greenyellow; */
  }
  .footer-container{
    width: 100%;
    background-image: url(../../assets/background_desgin.jpg);
    background-size: cover;
  }
  /* .main-form{
    width: 100%;
    background-image: url(../../assets/registration-bg.jpg);
    background-size:cover;
    background-position:right;
  } */
  